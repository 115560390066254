<template>
  <header class="fixed w-full bg-white shadow-sm z-50">
    <div 
      class="fixed inset-0 bg-black/30 transition-all duration-300 ease-in-out"
      :style="{
        opacity: activeDropdown !== null ? 1 : 0,
        visibility: activeDropdown !== null ? 'visible' : 'hidden',
        'pointer-events': activeDropdown !== null ? 'auto' : 'none'
      }"
      @click="hideDropdown"
    ></div>
    <nav 
      class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative"
      @mouseleave="hideDropdown"
    >
      <div class="flex justify-between h-16">
        <div class="flex items-center space-x-4">
          <router-link 
            to="/" 
            class="hover:opacity-80 transition-opacity"
            @mouseenter="hideDropdown"
          >
            <img 
              src="https://doc-oss.tduckcloud.com/doc/logoz.png" 
              alt="logo" 
              class="h-7"
            >
          </router-link>
        </div>

        <!-- 移动端汉堡菜单按钮 -->
        <div class="hamburger-menu h-full flex items-center">
          <button 
            class="flex items-center"
            @click="toggleMobileMenu"
          >
            <i class="fas fa-bars text-xl text-gray-700"></i>
          </button>
        </div>

        <!-- 桌面端导航 -->
        <div class="hidden lg:flex items-center space-x-8">
          <div 
            v-for="(item, index) in navItems" 
            :key="index" 
            class="dropdown relative group"
            @mouseenter="item.children ? showDropdown(index) : hideDropdown()"
          >
            <template v-if="item.children">
              <button 
                class="nav-link flex items-center space-x-1 text-gray-700 hover:text-primary whitespace-nowrap"
              >
                <span>{{ item.label }}</span>
                <i class="fas fa-chevron-down text-xs" />
              </button>
              <div 
                class="dropdown-wrapper absolute"
                :style="{
                  left: item.id === 1 ? '-200px' : '-100px',
                  display: activeDropdown === index ? 'block' : 'none'
                }"
                @mouseenter="handleMouseEnter(index)"
                @mouseleave="handleMouseLeave"
              >
                <div 
                  class="dropdown-menu mt-0 bg-white rounded-xl shadow-lg p-8" 
                  :class="{'w-[800px]': item.id !== 2, 'w-[600px]': item.id === 2}"
                >
                  <div v-if="item.id ==2" class="grid grid-cols-3 gap-8">
                    <div v-for="(category, categoryIndex) in item.children" :key="categoryIndex">
                      <div class="font-bold  text-gray-600 mb-4 text-base">
                        {{ category.title }}
                      </div>
                      <div class="grid gap-4">
                        <a
                          v-for="(subItem, subIndex) in category.items"
                          :key="subIndex"
                          :href="subItem.path"
                          target="_blank"
                          class="group flex items-center p-3 rounded-lg hover:bg-gray-50"
                        >
                          <i :class="subItem.icon + ' mr-3 text-[#4C4ED3]'" />
                          <div>
                            <div class="text-lg text-[#4C4ED3] font-medium">{{ subItem.label }}</div>
                            <div class="text-sm text-gray-500">{{ subItem.description }}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-else class="grid grid-cols-2 gap-8">
                    <div v-for="(category, categoryIndex) in item.children" :key="categoryIndex">
                      <div class="font-medium text-gray-600 mb-4 text-base">
                        {{ category.title }}
                      </div>
                      <div class="grid gap-4">
                        <a
                          v-for="(subItem, subIndex) in category.items"
                          :key="subIndex"
                          :href="subItem.path"
                          target="_blank"
                          class="group flex items-center p-3 rounded-lg hover:bg-gray-50"
                        >
                          <i :class="subItem.icon + ' mr-3 text-[#4C4ED3]'" />
                          <div>
                            <div class="text-lg text-[#4C4ED3] font-medium">{{ subItem.label }}</div>
                            <div class="text-sm text-gray-500">{{ subItem.description }}</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <a 
                v-if="item.external"
                :href="item.path"
                target="_blank"
                class="nav-link flex items-center space-x-1 text-gray-700 hover:text-primary whitespace-nowrap"
              >
                <span>{{ item.label }}</span>
              </a>
              <router-link
                v-else
                :to="item.path"
                class="nav-link flex items-center space-x-1 text-gray-700 hover:text-primary whitespace-nowrap"
              >
                <span>{{ item.label }}</span>
              </router-link>
            </template>
          </div>
          <div 
            class="flex items-center space-x-4"
            @mouseenter="hideDropdown"
          >
            <button class="px-6 py-2 text-[#4C4ED3] hover:text-[#4C4ED3]/80 border border-[#4C4ED3]/30 !rounded-button whitespace-nowrap" @click="clickConsult" >
              在线咨询
            </button>
            <button class="px-6 py-2 text-white bg-[#4C4ED3] hover:bg-[#4C4ED3]/80 !rounded-button whitespace-nowrap" @click="clickDemo"  >
              体验 Demo
            </button>
          </div>
        </div>

        <!-- 移动端侧边栏菜单 -->
        <div
          class="lg:hidden fixed inset-0 bg-black/30 transition-opacity duration-300 ease-in-out z-50"
          :class="{ 'opacity-0 invisible': !isMobileMenuOpen, 'opacity-100 visible': isMobileMenuOpen }"
          @click="closeMobileMenu"
        >
          <div 
            class="fixed right-0 top-0 bottom-0 w-[280px] bg-white shadow-xl transition-transform duration-300 ease-in-out"
            :class="{ 'translate-x-full': !isMobileMenuOpen, 'translate-x-0': isMobileMenuOpen }"
            @click.stop
          >
            <div class="p-4 overflow-y-auto h-full">
              <div class="flex justify-end mb-4">
                <button @click="closeMobileMenu">
                  <i class="fas fa-times text-xl text-gray-700"></i>
                </button>
              </div>
              
              <!-- 移动端菜单项 -->
              <div class="space-y-4">
                <div v-for="(item, index) in navItems" :key="index">
                  <template v-if="item.children">
                    <div>
                      <button 
                        class="flex items-center justify-between w-full py-2 text-gray-700"
                        @click="toggleMobileSubmenu(index)"
                      >
                        <span>{{ item.label }}</span>
                        <i :class="['fas', mobileActiveSubmenu === index ? 'fa-chevron-up' : 'fa-chevron-down', 'text-xs']"></i>
                      </button>
                      
                      <div 
                        v-show="mobileActiveSubmenu === index"
                        class="pl-4 mt-2 space-y-4"
                      >
                        <div v-for="(category, categoryIndex) in item.children" :key="categoryIndex">
                          <div class="font-bold text-gray-600 mb-2">{{ category.title }}</div>
                          <div class="space-y-2">
                            <a
                              v-for="(subItem, subIndex) in category.items"
                              :key="subIndex"
                              :href="subItem.path"
                              target="_blank"
                              class="block py-2 px-3 rounded-lg hover:bg-gray-50"
                            >
                              <div class="flex items-center">
                                <i :class="subItem.icon + ' mr-2 text-[#4C4ED3]'"></i>
                                <span class="text-[#4C4ED3]">{{ subItem.label }}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <a 
                      v-if="item.external"
                      :href="item.path"
                      target="_blank"
                      class="block py-2 text-gray-700"
                    >
                      {{ item.label }}
                    </a>
                    <router-link
                      v-else
                      :to="item.path"
                      class="block py-2 text-gray-700"
                    >
                      {{ item.label }}
                    </router-link>
                  </template>
                </div>
              </div>

              <!-- 移动端按钮组 -->
              <div class="mt-6 space-y-4">
                <button 
                  class="w-full px-6 py-2 text-[#4C4ED3] border border-[#4C4ED3]/30 rounded-button"
                  @click="clickConsult"
                >
                  在线咨询
                </button>
                <button 
                  class="w-full px-6 py-2 text-white bg-[#4C4ED3] rounded-button"
                  @click="clickDemo"
                >
                  体验 Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, inject, getCurrentInstance } from 'vue'
import { useModalStore } from '@/stores/modal'

defineOptions({
  name: 'TheHeader'
})

const activeDropdown = ref(null)
let timeoutId = null

const modalStore = useModalStore()

// 添加回移动端需要的状态和方法
const isMobileMenuOpen = ref(false)
const mobileActiveSubmenu = ref(null)

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value
  // 当菜单打开时禁止背景滚动
  if (isMobileMenuOpen.value) {
    document.body.classList.add('mobile-menu-open')
  } else {
    document.body.classList.remove('mobile-menu-open')
  }
}

const closeMobileMenu = () => {
  isMobileMenuOpen.value = false
  mobileActiveSubmenu.value = null
  document.body.classList.remove('mobile-menu-open')
}

const toggleMobileSubmenu = (index) => {
  mobileActiveSubmenu.value = mobileActiveSubmenu.value === index ? null : index
}

const showDropdown = (index) => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  activeDropdown.value = index
}

const hideDropdown = () => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  timeoutId = setTimeout(() => {
    activeDropdown.value = null
  }, 100)
}

const handleMouseEnter = (index) => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  activeDropdown.value = index
}

const handleMouseLeave = () => {
  hideDropdown()
}

const clickConsult = () => {
  window.open('https://pro.tduckcloud.com/s/QOt4xFOP', '_blank')
}

const clickDemo = () => {
  modalStore.openDemoModal()
}

const navItems = [
    {
    id: '1',
    label: '产品列表',
    children: [
      {
        title: '商业产品',
        items: [
          { label: 'TDuckX - vue3版', description: '企业级全功能版本，最新技术栈', icon: 'fas fa-crown',path: 'https://x.tduckcloud.com'   },
          { label: 'TDuckPro - vue2版', description: '适用于企业的商业版，基于vue2技术栈', icon: 'fas fa-rocket',path: 'https://pro.tduckcloud.com/' },
          { label: 'Nium - 智能二维码', description: '新一代二维码业务管理平台 活码/一物一码', icon: 'fas fa-qrcode',path: '/nium/' }
        ]
      },
      {
        title: '开源产品',
        items: [
          { label: 'TDUCK社区版', description: '免费开源的表单系统。基于MIT开源协议', icon: 'fas fa-dove', path: 'https://gitee.com/TDuckApp/tduck-platform' },
          { label: 'TReport可视化大屏', description: '开源报表设计与分析，支持与表单数据集成', icon: 'fas fa-chart-pie',path: 'https://gitee.com/TDuckApp/tduck-report-platform' }
        ]
      }
    ]
  },
  {
    id: '2',
    label: '文档',
    children: [
            {
        title: 'TDuckX',
        items: [
          { label: '产品简介', description: '商业版授权说明', icon: 'fas fa-shield-alt',path: 'https://www.tduckcloud.com/doc/help/RWGsm4AR' },
          { label: '技术文档', description: '技术能力介绍', icon: 'fas fa-building',path: 'https://www.tduckcloud.com/doc/x/n71e10Xi' },
          { label: '更新日志', description: '产品功能更新', icon: 'fas fa-tools',path: 'https://www.tduckcloud.com/doc/help/rXNZA5Uw' }
        ]
      }, 
      {
        title: 'TDuckPro',
        items: [
          { label: '部署教程', description: '专业版安装部署', icon: 'fas fa-server', path: 'https://doc.tduckcloud.com/proDeploy/' },
          { label: '功能介绍', description: '专业版特色功能', icon: 'fas fa-star', path: 'https://doc.tduckcloud.com/functionDesc/' },
          { label: '更新日志', description: '产品功能更新日志', icon: 'fas fa-plug', path: 'https://doc.tduckcloud.com/buyPro/changeLog.html' }
        ]
      },{
        title: '社区版',
        items: [
          { label: '使用文档', description: '详细的使用说明', icon: 'fas fa-book', path: 'https://doc.tduckcloud.com/' },
          { label: '一键部署', description: '宝塔快速部署文档', icon: 'fas fa-code', path: 'https://doc.tduckcloud.com/openSource/deploy/deployforbt.html' }
        ]
      },
    ]
  },
  {
    label: '用户案例',
    path: '/case/CaseList',
    external: false
  },
  {
    label: '产品定价',
    path: '/scheme/Price',
    external: false
  },
  {
    label: '开源仓库',
    path: 'https://gitee.com/TDuckApp/tduck-platform/',
    external: true
  }
]

// ,
//   {
//     label: '新推官计划🔥',
//     path: 'https://x.tduckcloud.com/s/c8rEqQe3',
//     external: true
//   }
</script>

<style scoped>
.nav-link {
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgb(139 92 246);
  transition: width 0.3s;
}

.nav-link:hover::after {
  width: 100%;
  background-color: rgb(139 92 246);
}

.dropdown-wrapper {
  top: calc(100% + 10px);
  pointer-events: none;
}

.dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateY(10px);
  z-index: 60;
  pointer-events: auto;
}

.dropdown-wrapper[style*="display: block"] .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* 防止移动端菜单打开时页面滚动 */
:global(body.mobile-menu-open) {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .hamburger-menu {
    display: none !important;
  }
}
</style> 